/**
 * Copyright 2022 Beijing Volcano Engine Technology Co., Ltd. All Rights Reserved.
 * SPDX-license-identifier: BSD-3-Clause
 */
.wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  margin-bottom: 10px;
}
.wrapper .box {
  margin-right: 16px;
}
.wrapper .seeMore {
  display: flex;
  padding: 6px 12px;
  justify-content: center;
  align-items: center;
  gap: var(--border-radius-small, 4px);
  border-radius: 6px;
  background: linear-gradient(96deg, rgba(22, 100, 255, 0.1) 0%, rgba(128, 64, 255, 0.1) 97.7%);
}
.wrapper .seeMore .seeMoreText {
  font-family: "PingFang SC";
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  /* 169.231% */
  letter-spacing: 0.039px;
  background: var(--Linear, linear-gradient(90deg, #004FFF 38.86%, #9865FF 100%));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
:global .ant-modal-content {
  border-radius: 8px;
}
:global .ant-modal-footer {
  border-top: 0px;
}
:global .ant-modal-body {
  padding-top: 8px;
  padding-bottom: 16px;
}
:global .ant-modal-header {
  border-bottom: 0px;
  border-radius: 8px;
}
.footer {
  width: calc(100% - 12px);
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding-bottom: 16px;
}
.footer .cancel {
  width: 88px;
  height: 32px;
  gap: 4px;
  border-radius: 6px;
  border: 1px solid var(--line-color-border-3, #dde2e9);
}
.footer .confirm {
  width: 88px;
  height: 32px;
  border-radius: 6px;
  background: linear-gradient(95.87deg, #1664FF 0%, #8040FF 97.7%);
  color: white;
}
.modalInner {
  width: 100%;
  display: flex;
  flex: row;
  flex-wrap: wrap;
  gap: 12px;
}
