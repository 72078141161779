/**
 * Copyright 2022 Beijing Volcano Engine Technology Co., Ltd. All Rights Reserved.
 * SPDX-license-identifier: BSD-3-Clause
 */
.wrapper {
  width: max-content;
  height: 100%;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.wrapper .info {
  width: 100%;
  border-radius: 16px;
  margin-bottom: 24px;
  background-color: white;
  border: 1px solid var(--line-color-border-2, #eaedf1);
  padding: 16px 24px;
}
.wrapper .info .titleLine {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: 500;
  line-height: 32px;
  color: #0c0d0e;
  height: 22px;
}
.wrapper .info .titleLine :global div.ant-typography,
.wrapper .info .titleLine :global .ant-typography p {
  margin-bottom: 0px;
}
.wrapper .info .titleLine .value {
  width: 65%;
  font-size: 12px;
  font-weight: 500;
  margin-left: 5px;
}
.wrapper .info .normalLine {
  color: #42464E;
  /* Body/body-1 regular */
  font-family: "PingFang SC";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 166.667% */
  letter-spacing: 0.036px;
  opacity: 0.5;
}
.wrapper .info .buttonArea {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 16px;
}
.wrapper .info .buttonArea .logoutButton {
  width: 48px;
  border-radius: 6px;
  padding: 5px 8px;
  margin-right: 16px;
  color: var(--text-color-text-2, #42464E);
  text-align: center;
  /* Body/body-2 medium */
  font-family: "PingFang SC";
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  /* 169.231% */
  letter-spacing: 0.039px;
}
.wrapper .info .buttonArea .getMore {
  color: #fff;
  height: 32px;
  width: max-content;
  text-shadow: none;
  box-shadow: none;
  border: none;
  padding: 0px 24px;
  background: linear-gradient(95.87deg, #1664FF 0%, #8040FF 97.7%);
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: var(--Primary-Neutral-0, #FFF);
  text-align: center;
  /* Body/body-2 medium */
  font-family: "PingFang SC";
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
}
.wrapper .info .buttonArea .getMore:hover,
.wrapper .info .buttonArea .getMore:active {
  background: linear-gradient(95.87deg, #1664FF 0%, #8040FF 97.7%);
  opacity: 0.8;
  text-shadow: none;
  box-shadow: none;
  border: none;
}
.wrapper .info .buttonArea .getMore::after {
  display: none;
}
.wrapper .info .buttonArea .getMore[disabled],
.wrapper .info .buttonArea .getMore[disabled]:hover {
  color: #fff;
  background: linear-gradient(95.87deg, #1664FF 0%, #8040FF 97.7%);
  opacity: 0.8;
}
.wrapper .setting {
  position: relative;
  width: 100%;
  border-radius: 16px;
  background-color: white;
  border: 1px solid var(--line-color-border-2, #eaedf1);
  padding: 16px 24px 16px 24px;
  box-sizing: border-box;
}
.wrapper .setting .divider {
  margin: 16px 0px;
}
.wrapper .resetTime {
  position: relative;
  width: 100%;
  border-radius: 16px;
  margin-top: 24px;
  background-color: white;
  border: 1px solid var(--line-color-border-2, #eaedf1);
  padding: 8px 24px 8px 24px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.wrapper .resetTime .normalLine {
  color: #42464E;
  /* Body/body-1 regular */
  font-family: "PingFang SC";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 166.667% */
  letter-spacing: 0.036px;
  opacity: 0.8;
}
