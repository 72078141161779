/**
 * Copyright 2022 Beijing Volcano Engine Technology Co., Ltd. All Rights Reserved.
 * SPDX-license-identifier: BSD-3-Clause
 */
.wrapper {
  width: max-content;
  padding: 4px 4px;
  border-radius: 4px;
  border: 1px solid;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 4px;
  align-items: center;
  background: var(--background-color-bg-5, #f1f3f5);
  border: 0px;
}
.wrapper .item {
  cursor: pointer;
  color: var(--text-color-text-2, #42464e);
  font-family: PingFang SC;
  font-size: 13px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.003em;
  text-align: center;
  border: 0px;
}
.wrapper .selected {
  box-shadow: 0px 0px 0px 1px rgba(213, 219, 227, 0.7);
  background: var(--background-color-bg-1, #ffffff);
  border-radius: 4px;
}
.wrapper .selected-text {
  background: linear-gradient(90deg, #004FFF 38.86%, #9865FF 100%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  font-family: PingFang SC;
  font-size: 13px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0.003em;
}
