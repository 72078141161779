/**
 * Copyright 2022 Beijing Volcano Engine Technology Co., Ltd. All Rights Reserved.
 * SPDX-license-identifier: BSD-3-Clause
 */
/* AudioWave.css */
@keyframes audioWave {
  0% {
    transform: scale(0.5);
    opacity: 1;
  }
  100% {
    transform: scale(2.5);
    opacity: 0;
  }
}
.wave-container {
  position: absolute;
  width: 150px;
  height: 150px;
}
.wave {
  position: absolute;
  border: 1px solid gray;
  border-color: rgba(0, 0, 0, 0.06);
  border-radius: 50%;
  width: 100%;
  height: 100%;
  animation: audioWave 9s infinite;
  opacity: 0;
}
.wave:nth-child(1) {
  animation-delay: -1s;
}
.wave:nth-child(2) {
  animation-delay: -2s;
}
.wave:nth-child(3) {
  animation-delay: -3s;
}
.wave:nth-child(4) {
  animation-delay: -4s;
}
.wave:nth-child(5) {
  animation-delay: -5s;
}
.wave:nth-child(6) {
  animation-delay: -6s;
}
.wave:nth-child(7) {
  animation-delay: -7s;
}
.wave:nth-child(8) {
  animation-delay: -8s;
}
.wave:nth-child(9) {
  animation-delay: -9s;
}
