/**
 * Copyright 2022 Beijing Volcano Engine Technology Co., Ltd. All Rights Reserved.
 * SPDX-license-identifier: BSD-3-Clause
 */
.wrapper {
  width: 100%;
  height: 20px;
  line-height: 20px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 16px;
}
.wrapper .title {
  font-family: PingFang SC;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.003em;
  text-align: left;
  margin-left: 6px;
}
