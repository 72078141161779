/**
 * Copyright 2022 Beijing Volcano Engine Technology Co., Ltd. All Rights Reserved.
 * SPDX-license-identifier: BSD-3-Clause
 */
.wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 24px;
}
.wrapper .label {
  font-family: PingFang SC;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.003em;
  margin-right: 16px;
}
.wrapper .margin {
  margin-left: 10px;
}
.wrapper .item {
  padding: 4px 16px 4px 16px;
  border-radius: 24px;
  border: 1px 0px 0px 0px;
  background: linear-gradient(0deg, rgba(249, 247, 255, 0.4), rgba(249, 247, 255, 0.4)), linear-gradient(101.19deg, rgba(55, 63, 255, 0.02) 1.62%, rgba(55, 63, 255, 0) 99.44%);
}
.wrapper .item:hover {
  border: 1px 0px 0px 0px;
  color: rgba(0, 0, 0, 0.85);
  border-color: #d9d9d9;
  background: linear-gradient(0deg, rgba(249, 247, 255, 0.9), rgba(249, 247, 255, 0.7)), linear-gradient(11.19deg, rgba(25, 27, 98, 0.6) 1.62%, rgba(55, 63, 255, 0.1) 99.44%);
}
.wrapper .item:active,
.wrapper .item:focus {
  opacity: 1;
  border: 1px 0px 0px 0px;
  color: rgba(0, 0, 0, 0.85);
  border-color: #d9d9d9;
}
