/**
 * Copyright 2022 Beijing Volcano Engine Technology Co., Ltd. All Rights Reserved.
 * SPDX-license-identifier: BSD-3-Clause
 */
 @meetingBackgroundColor: #1e2128;

.flex-box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.split-line(@h, @m) {
  display: inline-block;
  height: @h;
  width: 0;
  border-right: 1px solid #4e5969;
  margin: 0 @m;
}

.place-holder {
  font-family: "PingFang SC";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #86909c;
  position: relative;
  left: 5px;
}
