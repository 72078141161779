/**
 * Copyright 2022 Beijing Volcano Engine Technology Co., Ltd. All Rights Reserved.
 * SPDX-license-identifier: BSD-3-Clause
 */
.suffix {
  margin-left: 12px;
  border-radius: 4px;
}
.form {
  width: 100%;
}
