/**
 * Copyright 2022 Beijing Volcano Engine Technology Co., Ltd. All Rights Reserved.
 * SPDX-license-identifier: BSD-3-Clause
 */
:global .ant-spin-container {
  height: 100vh;
}
:global .ant-spin-nested-loading > div > .ant-spin {
  width: 100%;
  height: 100vh;
  max-height: 100vh;
}
.main {
  width: 100%;
  height: calc(100% - 48px);
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 24px 124px;
  box-sizing: border-box;
}
.main .mainArea {
  width: calc(100% - 200px);
  height: 100%;
  margin-right: 2%;
}
.main .operationArea {
  width: max-content;
  height: 100%;
}
