/**
 * Copyright 2022 Beijing Volcano Engine Technology Co., Ltd. All Rights Reserved.
 * SPDX-license-identifier: BSD-3-Clause
 */
.wrapper {
  width: 100%;
  height: 100%;
  background-color: white;
  border: 1px solid var(--line-color-border-2, #eaedf1);
  border-radius: 16px;
  padding: 20px 12.5%;
}
.wrapper .space {
  width: 100%;
  min-height: 40px;
}
.wrapper .doubaoIcon {
  min-height: 111px;
  width: 111px;
  height: 111px;
}
.wrapper .interruptTag {
  width: max-content;
  height: 22px;
  padding: 0px 6px 0px 6px;
  border-radius: 4px;
  margin-left: 4px;
  font-family: PingFang SC;
  font-size: 12px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.003em;
  color: var(--text-color-text-3, #737a87);
  background: var(--security-unknown-tag-unknown-1, #f1f3f5);
}
.wrapper .welcome {
  font-family: PingFang SC;
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: 0.003em;
  text-align: left;
  margin-top: 8px;
}
.wrapper .weight {
  background: linear-gradient(90deg, #004FFF 38.86%, #9865FF 100%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}
.wrapper .tip {
  font-family: PingFang SC;
  font-size: 13px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.003em;
  text-align: left;
  color: rgba(27, 30, 61, 0.6);
  margin-top: 18px;
  margin-bottom: 18px;
}
.wrapper .tagProblem {
  width: max-content;
  border-radius: 4px;
  font-family: PingFang SC;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.003em;
  text-align: center;
  margin-bottom: 12px;
  color: #42464e;
}
.wrapper .conversation {
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;
  position: relative;
  height: calc(75% - 12px);
  display: flex;
  flex-direction: column;
  padding-bottom: 12px;
}
.wrapper .conversation .aiReadying {
  font-family: PingFang SC;
  font-size: 16px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0.003em;
  color: rgba(27, 30, 61, 0.6);
  margin-top: 12px;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.wrapper .conversation .aiReading-spin {
  margin-right: 12px;
}
.wrapper .conversation::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}
.wrapper .conversation::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0);
  border-radius: 0px;
}
.wrapper .conversation::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0);
  border-radius: 0px;
}
.wrapper .sentence {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}
.wrapper .user {
  width: max-content;
  border: 0px solid;
  align-self: flex-end;
  padding: 8px 12px 8px 12px;
  border-radius: 12px 0px 12px 12px;
  background: var(--background-color-bg-5, #f1f3f5);
  margin-top: 12px;
}
.wrapper .robot {
  font-family: PingFang SC;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.003em;
  border: 0px solid;
  align-self: flex-start;
  padding: 3px 12px 3px 0px;
}
.wrapper .userTalkingWave {
  height: 100px;
}
.wrapper .userStopTalkingWave {
  height: 100px;
  transform: scaleY(0.5);
}
.wrapper .status {
  overflow: hidden;
  width: 100%;
  height: 25%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
}
.wrapper .status .status-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.wrapper .status .status-row .status-icon {
  width: 24px;
  height: 24px;
  margin-right: 6px;
}
.wrapper .status .status-row .status-text {
  font-family: PingFang SC;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0.003em;
}
.wrapper .status .desc {
  font-family: PingFang SC;
  font-size: 10px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.003em;
  text-align: center;
  color: var(--text-color-text-4, #c7ccd6);
}
.wrapper .status .micNotify {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.wrapper .status .micReopen {
  position: relative;
  width: 107px;
  height: 40px;
  padding: 5px 16px 5px 16px;
  margin-left: 12px;
  margin-right: 12px;
  background-clip: padding-box;
  /* 确保背景不覆盖边框 */
  border-radius: 12px;
}
.wrapper .status .micReopen:hover,
.wrapper .status .micReopen:active,
.wrapper .status .micReopen:focus {
  opacity: 1;
  color: rgba(0, 0, 0, 0.85);
  border-color: #d9d9d9;
}
.wrapper .interrupt {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 12px;
  width: max-content;
  line-height: 28px;
  padding: 1px 6px 1px 6px;
  border-radius: 4px;
  margin-left: 4px;
  font-family: PingFang SC;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.003em;
  text-align: left;
  box-shadow: 0px 0px 0px 1px #dde2e9;
  color: var(--text-color-text-3, #737a87);
}
.wrapper .interrupt:hover,
.wrapper .interrupt:active,
.wrapper .interrupt:focus {
  opacity: 1;
  border-color: #d9d9d9;
}
.wrapper .interrupt img {
  margin-right: 8px;
}
