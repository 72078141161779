/**
 * Copyright 2022 Beijing Volcano Engine Technology Co., Ltd. All Rights Reserved.
 * SPDX-license-identifier: BSD-3-Clause
 */
/**
 * Copyright 2022 Beijing Volcano Engine Technology Co., Ltd. All Rights Reserved.
 * SPDX-license-identifier: BSD-3-Clause
 */
.flex-box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.place-holder {
  font-family: "PingFang SC";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #86909c;
  position: relative;
  left: 5px;
}
@keyframes fadeInOut {
  0% {
    opacity: 0;
  }
  5% {
    opacity: 0.2;
  }
  10% {
    opacity: 0.4;
  }
  15% {
    opacity: 0.6;
  }
  20% {
    opacity: 0.8;
  }
  25% {
    opacity: 1;
  }
  30% {
    opacity: 1;
  }
  40% {
    opacity: 1;
  }
  45% {
    opacity: 0.8;
  }
  50% {
    opacity: 0.6;
  }
  55% {
    opacity: 0.4;
  }
  60% {
    opacity: 0.2;
  }
  65% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@keyframes floatAnimationLeft {
}
@keyframes floatAnimationRight {
}
.animatedElement {
  animation: fadeInOut 8s 0.5s ease-in-out infinite, floatAnimationLeft 3s 0s ease-in-out infinite;
}
.animatedElement15 {
  animation: fadeInOut 8s 2s ease-in-out infinite, floatAnimationRight 3s 0s ease-in-out infinite;
}
.container {
  width: 100%;
  height: 100%;
}
.container :global .ant-form-item-explain,
.container :global .ant-form-item-extra {
  line-height: 24px;
}
.container :global .ant-btn-primary[disabled] {
  background-color: #4080ff;
  opacity: 0.3;
  border-color: #4080ff;
}
.container :global .ant-form-item {
  margin-bottom: 32px;
  transition: none;
}
.container :global .ant-form-item-with-help {
  margin-bottom: 8px;
}
.container :global .ant-form-item-has-error {
  height: 72px;
}
.container :global .ant-form-item-has-error :not(.ant-input-disabled).ant-input,
.container :global .ant-form-item-has-error :not(.ant-input-affix-wrapper-disabled).ant-input-affix-wrapper,
.container :global .ant-form-item-has-error :not(.ant-input-disabled).ant-input:hover,
.container :global .ant-form-item-has-error :not(.ant-input-affix-wrapper-disabled).ant-input-affix-wrapper:hover {
  background: #1c222d;
  color: #fff;
  border-color: #ff4d4f;
}
.container :global .ant-row .ant-form-item .ant-form-item-has-success .ant-form-item-explain {
  display: none !important;
}
.container :global .ant-show-help-leave-active {
  min-height: 0;
  display: none !important;
}
.container :global .ant-input-affix-wrapper {
  height: 48px;
  border-radius: 8px;
  background: #1c222d;
  border-color: transparent;
  color: #fff;
}
.container :global .ant-input-affix-wrapper:hover {
  border-color: #40a9ff;
}
.container :global .ant-input-affix-wrapper:focus,
.container :global .ant-input-affix-wrapper-focused {
  border-color: #40a9ff !important;
  box-shadow: 0 0 0 2px #1890ff33 !important;
  background: #1c222d;
}
.container :global .ant-input-affix-wrapper input::placeholder {
  font-family: "PingFang SC";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #86909c;
  position: relative;
  left: 5px;
}
.container :global .ant-input-affix-wrapper button {
  background: rgba(255, 255, 255, 0.1);
  color: #fff;
  border: none;
  border-radius: 4px;
}
.container :global .ant-input-affix-wrapper :global input {
  color: #fff;
}
.container :global .ant-input-affix-wrapper :global input:focus,
.container :global .ant-input-affix-wrapper :global input-focused {
  background: #1c222d;
}
.container :global .ant-input {
  background: #1c222d;
}
.container :global .ant-input input::placeholder {
  font-family: "PingFang SC";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #86909c;
  position: relative;
  left: 5px;
}
.container :global .ant-checkbox input,
.container :global .ant-checkbox-inner {
  width: 16px;
  height: 16px;
  border-radius: 16px;
}
.login-input {
  border-radius: 8px;
  color: #fff;
  height: 48px;
  border-color: transparent;
}
.login-input:hover {
  border-color: #40a9ff;
}
.login-input:focus,
.login-input-focused {
  border-color: #40a9ff !important;
  box-shadow: 0 0 0 2px #1890ff33 !important;
  background: #1c222d;
}
.login-input::placeholder {
  font-family: "PingFang SC";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #86909c;
  position: relative;
  left: 5px;
}
.form-wrapper {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  height: calc(100% - 50px);
}
.main {
  width: 440px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.main .doubao {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 36px;
}
.main .doubao .rippleWave {
  position: absolute;
  top: 0;
}
.main .doubao .text {
  font-family: PingFang SC;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.003em;
  text-align: center;
}
.main .doubao .doubaoLogo {
  margin-bottom: 18px;
}
.main .doubao .bubble1 {
  opacity: 0;
  animation-fill-mode: forwards;
  position: absolute;
  top: 0px;
  left: 36px;
}
.main .doubao .bubble2 {
  opacity: 0;
  animation-fill-mode: forwards;
  position: absolute;
  top: 64px;
  right: 36px;
}
.main :global h1 {
  color: #fff;
  text-align: center;
  margin: 0 0 50px 0px;
}
.startButton {
  border-radius: 8px;
  height: 50px;
  width: 158px;
  text-shadow: none;
  box-shadow: none;
  border: none;
  background: linear-gradient(95.87deg, #1664FF 0%, #8040FF 97.7%);
}
.startButton:hover,
.startButton:focus,
.startButton:active {
  background: linear-gradient(95.87deg, #1664FF 0%, #8040FF 97.7%);
  opacity: 0.8;
  text-shadow: none;
  box-shadow: none;
  border: none;
}
.startButton::after {
  display: none;
}
.startButton[disabled],
.startButton[disabled]:hover {
  color: #fff;
  background: linear-gradient(95.87deg, #1664FF 0%, #8040FF 97.7%);
  opacity: 0.8;
}
.mediaIcon :global svg {
  transform: scale(0.66) translate(0, 4px);
  transform-origin: center;
}
.settingIcon {
  background: transparent;
  margin-bottom: 0;
}
.settingIcon:hover {
  background: none;
}
.settingIcon :global svg {
  transform: scale(1);
}
