/**
 * Copyright 2022 Beijing Volcano Engine Technology Co., Ltd. All Rights Reserved.
 * SPDX-license-identifier: BSD-3-Clause
 */
.row {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.row .icon {
  margin-right: 6px;
}
:global .ant-form-item-has-success {
  margin-bottom: 12px;
}
.wrapper {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 12px;
}
.wrapper .input {
  border-radius: 12px;
  line-height: 28px;
  background: linear-gradient(0deg, rgba(249, 247, 255, 0.4), rgba(249, 247, 255, 0.4)), linear-gradient(101.19deg, rgba(55, 63, 255, 0.02) 1.62%, rgba(55, 63, 255, 0) 99.44%);
}
.wrapper .third {
  border-radius: 4px;
}
.wrapper .hasContent {
  background: linear-gradient(0deg, rgba(249, 247, 255, 0.4), rgba(249, 247, 255, 0.4)), linear-gradient(101.19deg, rgba(55, 63, 255, 0.02) 1.62%, rgba(55, 63, 255, 0) 99.44%);
}
.wrapper :global .ant-form-item-no-colon {
  font-family: PingFang SC;
  font-size: 13px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.003em;
  text-align: left;
}
.wrapper::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
.wrapper::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}
.wrapper::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}
.confirmModal {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.confirmModal img {
  margin-right: 6px;
}
