/**
 * Copyright 2022 Beijing Volcano Engine Technology Co., Ltd. All Rights Reserved.
 * SPDX-license-identifier: BSD-3-Clause
 */
.header {
  height: 48px;
  background: white;
  width: 100%;
  display: flex;
  align-items: center;
  padding-right: 8px;
  padding-left: 8px;
  justify-content: space-between;
  position: relative;
}
.header-logo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 16px;
}
.header-logo :global img {
  height: 24px;
}
.header-logo-text {
  background: linear-gradient(90deg, #004FFF 38.86%, #9865FF 100%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  font-size: 16px;
}
.header-right {
  z-index: 2;
  color: #fff;
  display: flex;
  align-items: center;
}
.header-right :global span {
  height: 24px;
}
.header-setting-btn {
  background-color: transparent;
  border: none;
  margin-right: 12px;
}
.header-setting-btn:hover {
  background-color: transparent;
  cursor: pointer;
}
.header-pop :global .ant-popover-arrow {
  left: 16px;
}
.header-pop :global .ant-popover-arrow .ant-popover-arrow-content:before {
  background-color: white;
}
.header-pop :global .ant-popover-content {
  margin-left: 12px;
}
.header-pop :global .ant-popover-inner {
  margin-right: 12px;
}
.header-pop :global .ant-popover-inner-content {
  padding: 0;
  background-color: white;
  position: relative;
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 7px;
  padding-top: 7px;
  cursor: pointer;
  color: black;
}
.header-pop :global .ant-popover-inner-content div {
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
}
.header-pop :global .ant-popover-inner-content div:hover {
  color: #1664ff;
}
.divider {
  margin-top: 2px;
  margin-bottom: 2px;
  min-width: 70%;
  width: 70%;
}
.header-right-text {
  color: #86909c;
}
