/**
 * Copyright 2022 Beijing Volcano Engine Technology Co., Ltd. All Rights Reserved.
 * SPDX-license-identifier: BSD-3-Clause
 */
.row {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.row .firstPart {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 90%;
  color: var(--text-color-text-2, var(--text-color-text-2, #42464E));
  text-align: center;
  /* Body/body-2 medium */
  font-family: "PingFang SC";
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  /* 169.231% */
  letter-spacing: 0.039px;
}
.row .finalPart {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 10%;
  justify-content: flex-end;
}
.row .finalPart .rightOutlined {
  font-size: 12px;
}
.row .icon {
  margin-right: 4px;
}
.footer {
  width: calc(100% - 12px);
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}
.footer .cancel {
  width: 88px;
  height: 32px;
  border-radius: 6px;
  border: 1px solid var(--line-color-border-3, #dde2e9);
  margin-right: 12px;
}
.footer .confirm {
  width: 88px;
  height: 32px;
  border-radius: 6px;
  background: linear-gradient(95.87deg, #1664FF 0%, #8040FF 97.7%);
  color: white;
}
:global .ant-drawer-body {
  padding: 12px 24px 0px 24px;
}
