/**
 * Copyright 2022 Beijing Volcano Engine Technology Co., Ltd. All Rights Reserved.
 * SPDX-license-identifier: BSD-3-Clause
 */
.bubbleWrapper {
  position: relative;
  width: 118px;
  height: 46px;
}
.bubbleWrapper .bubbleLogo {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
  z-index: 1;
}
.bubbleWrapper .bubbleText {
  position: absolute;
  z-index: 2;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  color: black;
  font-weight: 400;
  line-height: 16px;
  text-align: center;
  top: 0px;
}
.bubbleWrapper .bubble-direction-left {
  transform: scaleX(-1);
}
