/**
 * Copyright 2022 Beijing Volcano Engine Technology Co., Ltd. All Rights Reserved.
 * SPDX-license-identifier: BSD-3-Clause
 */
.wrapper {
  width: 260px;
  height: 88px;
  padding: 20px 16px 20px 16px;
  border-radius: 12px;
  border: 1px solid;
  border-color: rgba(22, 100, 255, 0.3);
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
}
.wrapper .icon {
  border-radius: 50%;
  margin-right: 20px;
  width: 48px;
  height: 48px;
}
.wrapper .content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.wrapper .content .label {
  font-family: PingFang SC;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
}
.wrapper .content .description {
  font-family: PingFang SC;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
}
.wrapper:hover {
  box-shadow: 0px 5px 6px 0px rgba(82, 102, 133, 0.15);
}
.active {
  position: relative;
  border: 1px solid;
  border-color: #0068ff;
}
.active .checkIcon {
  position: absolute;
  bottom: 0px;
  right: 0px;
}
